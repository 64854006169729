import { Grid } from "@mui/material";
import error500 from "assets/images/illustrations/error-500.png";
import error404 from "assets/images/illustrations/error-404.png";
// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";
// Authentication layout components
import PageLayout from "@/layouts/LayoutContainers/PageLayout";
// NextJS Material Dashboard 2 PRO components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import Link from "next/link";

function Error({ statusCode }) {
  const { d1, d3, d4, d5 } = typography;

  return (
    <PageLayout white>
      <SoftBox my={18} height="calc(100vh - 18rem)">
        <Grid container spacing={3} justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
          <Grid item xs={11} sm={9} container alignItems="center">
            <Grid item xs={12} lg={6}>
              <SoftBox fontSize={{ xs: d5.fontSize, sm: d4.fontSize, md: d3.fontSize, lg: d1.fontSize }} lineHeight={1.2}>
              <SoftTypography variant="inherit" color={statusCode == "404" ? "error" : "warning"} textGradient fontWeight="bold">
                  Error {statusCode}
                </SoftTypography>
              </SoftBox>
              <SoftTypography variant="h2" color="dark" textGradient fontWeight="bold">
                {statusCode == "404" ? "Erm. Page not found" : "Something went wrong"}
              </SoftTypography>
              <SoftBox mt={1} mb={2}>
                <SoftTypography variant="body1" color="text" opacity={1}>
                  We suggest you to go to the homepage while we solve this issue.
                </SoftTypography>
              </SoftBox>
              <SoftBox mt={4} mb={2}>
                <Link href="/" passHref>
                  <SoftButton variant="gradient" color={statusCode == "404" ? "error" : "warning"}>
                    go to homepage
                  </SoftButton>
                </Link>
              </SoftBox>
            </Grid>
            <Grid item xs={12} lg={6}>
              <SoftBox component="img" src={statusCode == "404" ? error404.src : error500.src} alt="error-404" width="100%" />
            </Grid>
          </Grid>
        </Grid>
      </SoftBox>
    </PageLayout>
  );
}

Error.getInitialProps = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404
  return { statusCode };
};

export default Error;
